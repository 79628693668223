<template>
<CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>List of Promotion</h4>
              </CCol>
              <CCol md="4">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />                    
              </CCol>
              <CCol md="1">
                    <b-form-checkbox                     
                      v-model="cbactive"
                      @change="onModifyAllPromo"
                      name="cbactive"
                      value="true"
                      unchecked-value="false">
                      All 
                     </b-form-checkbox>
              </CCol>
              <CCol md="2"> 

                   <b-dropdown variant="primary" text="Action" size="md">
                    <router-link :to='"/promo/create"' tag="b-dropdown-item" > New Promo </router-link>
                    <router-link :to='"/promo/img/default"' tag="b-dropdown-item" > Default Image </router-link>
                  </b-dropdown>

              </CCol>
          </CRow>
          <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
          </CRow>
        </CCardHeader><br/>
        <CCardBody>
          <div class="table-responsive table">
	        <b-table striped hover  :items="items" :fields="fields" sort-by="createDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

	        <template v-slot:cell(startTime)="{ item }"> 
	              {{ item.startTime | dateWithTime }}
	        </template>  

	        <template v-slot:cell(endTime)="{ item }"> 
	              {{ item.endTime | dateWithTime }}
	        </template>   

	        <template v-slot:cell(createDate)="{ item }"> 
	              {{ item.createDate | dateSubstr }}
	        </template> 

          <template v-slot:cell(actions)="{ item }">
          <b-dropdown variant="info" text="Actions" size="md">
            <router-link :to='"/promo/details/" + item.promoCode' tag="b-dropdown-item" > Detail </router-link>
            <router-link :to='"/promo/upload/" + item.promoCode' tag="b-dropdown-item" > Image </router-link>             
            <router-link to="/promo" tag="b-dropdown-item">
                 <span v-on:click="removePromo(item.promoCode)">Remove</span>
            </router-link>
          </b-dropdown>
          </template>
	        </b-table>
          </div>

          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>  

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>      

</div>
</template>

<script>
import AuthService from '@/api/AuthService.js';
import PromoService from '@/api/PromoService.js';

 export default {
    name: "users",
    data() {
      return {
        items:[],             
        fields: [ 
       	  {
            name : 'promoCode',
            key: 'promoCode',
            label : 'Promo Code',
            sortable: true
          }, 
          {
            key: 'promoName',
            label : 'Promo Name',
            sortable: true
          }, 
          {
            key: 'promotionType',
            label : 'Promo Type',
            sortable: true
          }, 
          {
            key: 'currency',
            label : 'Currency',
            sortable: true
          }, 
          {
            key: 'startTime',
            label : 'Start Time',
            sortable: true
          }, 
          {
            key: 'endTime',
            label : 'End Time',
            sortable: true
          }, 
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }         
          
        ],       
        currentPage: 1,
        perPage: 10,
        filter: null,
        cbactive:false,
        seen:false,
        msg :'',
        color:''
      };
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },        
    },   

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)         
        },

        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


      },

    created () { 
       this.getActivePromoList();      
    },
    methods: { 

      onModifyAllPromo(){
        var status = this.stringToBoolean(this.cbactive);
        if(status){ this.getPromoList();
        } else { this.getActivePromoList(); }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },

      removePromo(promoCode){
          this.removePromo(promoCode);
      }, 

      removePromo: function(promoCode) {
        PromoService.removePromo(promoCode).then(resp => { 
         
           if(resp.message==="Success"){                  
              this.msg   = "Remove Promotion Success!"; 
              this.color = 'success'; this.seen  = true;  this.getPromoList();
            } else {
              this.msg   =  !resp.data.message ? "Remove Promotion Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }  

            setTimeout( () =>  this.seen=false , 5000);      

        }, error => {
          this.loading = false;
        });
      },  

      getPromoList: function() {
        PromoService.getPromoList().then(resp => {         
        this.items = resp.payload;  //console.log('all');
        }, error => {
          this.loading = false;
        });
      },  

      getActivePromoList: function() {
        PromoService.getActivePromoList().then(resp => {         
        this.items = resp.payload;  //console.log('active');
        }, error => {
          this.loading = false;
        });
      }
    },
    mounted() { 
       //console.log(process.env.VUE_APP_ROOT_API);
    },   
 }; 

</script>
<style>
    .table{overflow-y:scroll; }
</style>